import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="More Than A Smile | Best Cosmetic Dentist Toronto | The Smile Stylist" 
    description="The Smile Stylist is a leader in Cosmetic Dentistry with specialization in porcelain veneers and smile makeovers. Learn what makes him tick, and rise above the rest." 
    keywords="cosmetic dentistry" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="news-press hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="pr__con _w_70p">
              <h1>MORE THAN A SMILE</h1>
              <h2>DR. HISHAM KALOTI – Bestselling author,
                <br /> international speaker and philanthropist.</h2>
            </div>

          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p className="lead">
              Dr. Hisham Kaloti is a leader in cosmetic dentistry. In fact, his passion for creating one-of-a-kind smiles has been featured on national TV. But even more significantly, he knows the importance of balance in life. For him, it’s all about a great smile, hanging out with his family and giving back.
            </p>
            <p>If you think of philanthropy when you think of Dr. Hisham Kaloti, you’d be forgiven — he works hard to make the world a better place. It all started with a trip to Haiti with a friend, who was going as part of a mission after the earthquake in 2010. “A mission? I didn’t really know what he was talking about, but I said, ‘OK, I’ll go, too.’”</p>
            <p>When Dr. Kaloti arrived, he found a world he hadn’t known existed. There were people whose basic needs were not being met. He called his medical supplier and said he needed as many toothbrushes, as he could get. “We go into an orphanage, and I’m telling the kids about the toothbrushes, and they’re looking at me like they don’t understand what I’m saying,” he says. He thought it was a communication issue, but the translator said, “You know, we don’t have running water here.”</p>
            <p>“I came home and looked at my four kids and everything they had — Xboxes, beds, fluffy blankets and nightlights, and I thought, ‘Clearly I have missed the boat.’” He was fortunate enough to meet Alden Crowley, the founder of Another Joy Foundation in Las Vegas, Nevada, which delivers medical supplies, toys and games to children around the world on refurbished planes. “We got smart — we got together blankets, batteries and other things you might need after an earthquake, and I went with him. It was amazing; we met so many people,” he says.</p>
            <p>And once your eyes are opened, it’s impossible to put the blinders back on, of course. When Dr. Kaloti was vacationing with his friend Marco in Mexico, he met a woman in tears because her son, who was on the autism spectrum, couldn’t go to school — it was simply too far. Dr. Kaloti spearheaded the drive to convert a house in town into a school. The first day, 50 kids showed up, and the next day, another 50 kids. “I couldn’t believe the need,” he says. People were eager to help, including clinicians and instructors, and the school is now operating at full capacity.</p>
            <img src={ require("../../images/desktop/press-mta/first image.png" )} alt=" Dr Hisham Kaloti" />
            <h2>Funny how things turn around</h2>
            <p>Actually, if his Plan A had worked out, Dr. Kaloti may never have found his passion. Originally, he had wanted to follow in his father’s footsteps and become a cardiologist. But during his second year of undergraduate studies at Western University in London, Ont., he discovered he could apply to some professional schools before he finished his degree.</p>
            <p>So he applied to schools, including the School of Dentistry at the University of Michigan, where he got an interview. One of the interviewers asked Dr. Kaloti what he liked to do for fun. “I like to draw,” he said. The interview asked if Dr. Kaloti could draw him. “OK, I’ll draw a portrait of you, but if you like it, you have to get me into the program,” he’d said. The interviewer laughed, but two weeks later, Dr. Kaloti got his admission. “I’ve always been on the artistic side, but dentistry has been the perfect medium for me,” he says. “I can still draw, but I can make it more lasting and change people’s lives.”</p>
            <img src={ require("../../images/desktop/press-mta/Dr-Kaloti-Smile Stylist - top dentist.png" )} alt="Doing The Right Things for The Right Reason" />
            <h2>A love of work</h2>
            <p>After graduating, Dr. Kaloti and his brother founded a franchise, Dental Reflections, in the American Midwest, with 16 offices and more than 100 staff members and dentists. With offices from Waukesha, Wisc., to Toledo, Ohio, a workday sometimes involved six hours of commuting to an office, then back home. “I did this for 15 years, so when a group of investors walked in, they made us an offer and we just walked away,” he says.</p>
            <p>Dr. Kaloti returned to Canada and now operates The Smile Stylist in Oakville, Ont., blending his expertise in dentistry with his artistic ability. “I realized that my passion is smiles,” he says. “Most people contemplating improvement in their looks don’t know where to start. It’s not just the teeth — that’s only a small part of it. A smile is made up of the face, the colour of your eyes, the shape of your lips.” So when a patient comes into the office, the first thing they do is take a video. “We see the patients telling their story, and it helps us understand what they’re looking for at their deepest conscious level. Then we have a one-stop dream team that can deliver.”</p>
            <p>A smile design can cost thousands of dollars, but consultations are no charge. “If you choose to partake in [our] services, it’s wonderful, and if you choose not to, then hopefully we add some value to your life by adding to your dental IQ,” he says. Still, Dr. Kaloti was astounded at the far-reaching impact of his first book (Inside Job: Separating Fact from Fiction about Your Health). “Clinically we do one smile at a time, but people asking questions from across the States and Canada was more profound than I could ever have expected,” he says. That excitement launched his current book, The 14 Secrets Your Dentist Will Never Tell You.</p>
            <blockquote>“THE CHALLENGE IS THAT WE THINK OF OURSELVES IN THESE LITTLE BOXES AND WE DON’T REALLY SEE THE BIG SCHEME UNTIL WE OPEN OUR EYES”</blockquote>
            <img src={ require("../../images/desktop/press-mta/Dr-Kaloti-Smile Stylist.png" )} alt="A Love of Work" />
            <h2>Doing the right things for the right reason</h2>
            <p>Where does this kind of drive come from? In high school, a friend gave Dr. Kaloti a Tony Robbins CD. “He didn’t say anything that I didn’t already know, but the way he said it was amazing,” he says. “I thought, ‘This is powerful — if you put your mind to it, anything is achievable’ — and whether it was smart or naive, I decided to just accept it.”</p>
            <p>When a friend built a 300-office dental care business, Dr. Kaloti decided he wanted to be just like that, too. “And why not? People really care about your smile — your smile is everything. War and Peace starts with a smile. I could be the artist, painting smiles every day and making good money with it,” he says.</p>
            <p>But perhaps the greater lesson that Dr. Kaloti learned from Tony Robbins is that true happiness is not what you get, but what you give. “I didn’t understand that all that well, but it must have registered in the back of my brain somewhere,” he says. Indeed, it must have, because he has the awards to back him up, including the 2007 Donald A. Kerr Oral Pathology Award, the 2010 Kids Connection Award, the 2014 Best Dentist Award (Cambridge Times) and the 2016 Top Doc Award (Realself.com).</p>
            <p>Most of all, though, Dr. Kaloti can’t underestimate the power of one of his greatest mentors: his father. “My dad is retired now, but his passion was always being there for people,” he says. “I grew up witnessing people coming to his office and appreciating him for the work that he did.”</p>
            <img src={ require("../../images/desktop/press-mta/Dr-Kaloti-03.png" )} alt="Kaloti Foundation" />
            <h2>Finding the balance</h2>
            <p>“In the beginning of my career, I was all business, I can’t say that I raised my two older boys; my wife raised them,” he says. “So when the chance to sell the business came, I made a vow to myself to not let business take over. Now I’m happy to serve my patients four to five days a week, but I reserve weekends for my family.”</p>
            <p>And it’s the same with philanthropy. “The challenge is that we think of ourselves in these little boxes and we don’t really see the big scheme until we open our eyes,” he says. In fact, if he could change one thing about society, he would ask people to raise their heads out of their own little boxes and take a look around. “You have to get to the point where you can find a balance for success, a balance for business, a balance for family and a balance for giving back,” he adds.</p>
            <h2>What’s coming down the pipeline</h2>
            <p>Right now, Dr. Kaloti dedicates time to providing dental care to communities in Haiti, Mexico and Somalia through the Kaloti Foundation, a non-profit organization dedicated to improving children’s oral health. He’s also working on his latest project. With the help of his friend Ivan, he’s getting wheelchairs to people in Mexico. “In Oaxaca state, these kids have to be carried around because they don’t have wheelchairs,” he says.</p>
            <p>Ivan has provided him with a list of about 25 people who are basically confined to their homes, and Dr. Kaloti is now thinking of how to best fulfil this need. “All of a sudden, a toothbrush is not important, a laptop is not important, the Internet is not important,” he says. “These people just want to be able to get out of their house.” Dr. Kaloti wants to tell their stories. “They’ve been robbed of mobility and my goal is to tell the world that this individual exists,” he says. And he wants this project to grow. “I’d like to keep the wheelchair as the emblem of what we’re doing, because the wheelchair is freedom.”</p>
            <p>In the future, he’d like to take on a few motivated and dedicated young men and women and help guide them through some of the challenges that he went through so they can align themselves with their own vision. “To be able to pass that along — that to me would be the biggest gift,” he says quietly.</p>
          </div>
          <div className="col-sm-4 blog-right">
            <img alt="Free Teeth Whitening" src={ require("../../images/desktop/services/free-teeth-whitening.jpg" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.6s" />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage